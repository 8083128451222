import React from 'react';

export const SettingsIcon = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="white" />
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" stroke="#E0E0E0" />
      <path d="M22 22C24.21 22 26 20.21 26 18C26 15.79 24.21 14 22 14C19.79 14 18 15.79 18 18C18 20.21 19.79 22 22 22ZM22 24C19.33 24 14 25.34 14 28V30H30V28C30 25.34 24.67 24 22 24Z" fill="#6D788D" />
    </svg>

  );
};
