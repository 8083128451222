import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const InvoiceIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33325 3.41667V32.5833L9.24992 31.125L12.1666 32.5833L15.0833 31.125L17.9999 32.5833L20.9166 31.125L23.8333 32.5833L26.7499 31.125L29.6666 32.5833V3.41667L26.7499 4.87501L23.8333 3.41667L20.9166 4.87501L17.9999 3.41667L15.0833 4.87501L12.1666 3.41667L9.24992 4.87501L6.33325 3.41667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8334 12.1667H15.0834C14.3099 12.1667 13.568 12.474 13.021 13.0209C12.474 13.5679 12.1667 14.3098 12.1667 15.0833C12.1667 15.8569 12.474 16.5988 13.021 17.1457C13.568 17.6927 14.3099 18 15.0834 18H20.9167C21.6903 18 22.4322 18.3073 22.9791 18.8543C23.5261 19.4013 23.8334 20.1431 23.8334 20.9167C23.8334 21.6902 23.5261 22.4321 22.9791 22.9791C22.4322 23.526 21.6903 23.8333 20.9167 23.8333H12.1667"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 26.0208V9.97917"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
