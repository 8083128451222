import React from 'react';

export const AccountSettingsIcon = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 18C19.6569 18 21 16.6569 21 15C21 13.3431 19.6569 12 18 12C16.3431 12 15 13.3431 15 15C15 16.6569 16.3431 18 18 18Z" />
      <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" />
      <path d="M10 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" />
      <path d="M21.7 16.4L20.8 16.1" />
      <path d="M15.2 13.9L14.3 13.6" />
      <path d="M16.6 18.7L16.9 17.8" />
      <path d="M19.1 12.2L19.4 11.3" />
      <path d="M19.6 18.7L19.2 17.7" />
      <path d="M16.8 12.3L16.4 11.3" />
      <path d="M14.3 16.6L15.3 16.2" />
      <path d="M20.7 13.8L21.7 13.4" />
    </svg>
  );
};
