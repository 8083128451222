import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const TimerIcon: React.FC<Props> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9999 19.4583V13.625M31.1249 9.25001L28.2083 6.33334M15.0833 3.41667H20.9166M17.9999 31.125C11.5566 31.125 6.33325 25.9017 6.33325 19.4583C6.33325 13.015 11.5566 7.79167 17.9999 7.79167C24.4432 7.79167 29.6666 13.015 29.6666 19.4583C29.6666 25.9017 24.4432 31.125 17.9999 31.125Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
