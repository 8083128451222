import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const JobIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.41663 26.75C3.41663 27.1368 3.57027 27.5077 3.84376 27.7812C4.11725 28.0547 4.48819 28.2083 4.87496 28.2083H31.125C31.5117 28.2083 31.8827 28.0547 32.1562 27.7812C32.4296 27.5077 32.5833 27.1368 32.5833 26.75V23.8333C32.5833 23.4466 32.4296 23.0756 32.1562 22.8021C31.8827 22.5286 31.5117 22.375 31.125 22.375H4.87496C4.48819 22.375 4.11725 22.5286 3.84376 22.8021C3.57027 23.0756 3.41663 23.4466 3.41663 23.8333V26.75Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0834 15.0833V7.79166C15.0834 7.40489 15.237 7.03396 15.5105 6.76046C15.784 6.48697 16.1549 6.33333 16.5417 6.33333H19.4584C19.8451 6.33333 20.2161 6.48697 20.4896 6.76046C20.7631 7.03396 20.9167 7.40489 20.9167 7.79166V15.0833"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33337 22.375V18C6.33337 15.6794 7.25525 13.4538 8.89619 11.8128C10.5371 10.1719 12.7627 9.25 15.0834 9.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9166 9.25C23.2373 9.25 25.4629 10.1719 27.1038 11.8128C28.7448 13.4538 29.6666 15.6794 29.6666 18V22.375"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
