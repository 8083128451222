import React from 'react';
import NotificationDropdownIndex from '@/components/notification/NotificationDropdownIndex';

const NotificationsDropdown = () => {
  return (
    <div>
      <NotificationDropdownIndex />
    </div>
  );
};

export default NotificationsDropdown;
