import React from 'react';

export const EllipseIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '11'}
      height={props.height || '11'}
      viewBox="0 0 11 11"
      fill="none"
    >
      <circle cx="5.5" cy="5.5" r="5" fill="#34C100" />
    </svg>
  );
};
