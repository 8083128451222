import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const EnquiryIcon: React.FC<Props> = ({ color, height, width }) => {
  return (
    <svg
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0208 29.6667C14.8041 31.0945 18.0059 31.4812 21.0492 30.7572C24.0924 30.0332 26.777 28.246 28.6192 25.7178C30.4614 23.1895 31.3399 20.0865 31.0966 16.9677C30.8533 13.849 29.5041 10.9197 27.2922 8.70778C25.0802 6.49582 22.1509 5.14662 19.0322 4.90332C15.9135 4.66002 12.8104 5.53861 10.2822 7.38078C7.75395 9.22295 5.9668 11.9075 5.24278 14.9508C4.51877 17.994 4.9055 21.1958 6.33329 23.9792L3.41663 32.5833L12.0208 29.6667Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7562 13.625C14.0991 12.6504 14.7758 11.8285 15.6666 11.305C16.5573 10.7815 17.6046 10.5901 18.6229 10.7648C19.6413 10.9395 20.5649 11.4689 21.2303 12.2593C21.8957 13.0497 22.2598 14.0501 22.2583 15.0833C22.2583 18 17.8833 19.4583 17.8833 19.4583"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 25.2917H18.0146"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
