import { Input, Navbar, NavbarContent } from '@nextui-org/react';
import React from 'react';
import { SearchIcon } from '../icons/search-icon';
import { BurguerButton } from './burguer-button';
import NotificationsDropdown from './notificationsDropdown';
import { UserDropdown } from './userDropdown';
import Image from 'next/image';
import logoWhite from '../../../public/assets/images/logo-new-white.svg';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { formatDate } from '@/utility/formatDate';
import Link from 'next/link';
import { white } from 'next/dist/lib/picocolors';
interface Props {
  children: React.ReactNode;
}

export const NavbarWrapper = ({ children }: Props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const currentDate = new Date();
  return (
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <div style={{ backgroundColor: '#f3f8ff' }}>
        <Navbar
          height="70px"
          isBordered
          className="w-full flex justify-center items-center fixed right-0 bg-primary"
          classNames={{
            wrapper: 'w-full max-w-full',
          }}
          // style={{ backgroundColor: '#F3F8FF' }}
        >
          <NavbarContent className="md:hidden">
            <BurguerButton />
          </NavbarContent>
          {/* <NavbarContent className="w-full max-md:hidden"> */}
          <div className="md:ps-1">
            <Link href="/">
              <Image
                src={logoWhite}
                alt="logo"
                width={150}
                priority
                className="cursor-pointer"
              />
            </Link>
          </div>
          {/* </NavbarContent> */}
          {/* <NavbarContent className="hidden md:flex flex justify-start"> */}
          <div className="text-xl font-medium text-white">
            {user?.company_name} - {formatDate(currentDate)}
          </div>
          {/* </NavbarContent> */}

          <NavbarContent
            justify="end"
            className="w-fit data-[justify=end]:flex-grow-0"
          >
            <NotificationsDropdown />
            <NavbarContent>
              <UserDropdown />
            </NavbarContent>
          </NavbarContent>
        </Navbar>
      </div>
      <div className="px-4 py-4 lg:px-8 lg:py-6 w-full mt-[70px]">
        {children}
      </div>
    </div>
  );
};
