import React from 'react';

interface Props {
  color: string;
  width?: string;
  height?: string;
}

export const QuoteIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width || '36'}
      height={height || '36'}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.375 18H15.0834"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.375 12.1667H15.0834"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2084 25.2917V7.79167C28.2084 7.01812 27.9011 6.27625 27.3541 5.72927C26.8071 5.18229 26.0653 4.875 25.2917 4.875H6.33337"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1666 31.125H29.6666C30.4402 31.125 31.182 30.8177 31.729 30.2707C32.276 29.7237 32.5833 28.9819 32.5833 28.2083V26.75C32.5833 26.3632 32.4296 25.9923 32.1562 25.7188C31.8827 25.4453 31.5117 25.2917 31.125 25.2917H16.5416C16.1549 25.2917 15.7839 25.4453 15.5104 25.7188C15.2369 25.9923 15.0833 26.3632 15.0833 26.75V28.2083C15.0833 28.9819 14.776 29.7237 14.229 30.2707C13.682 30.8177 12.9402 31.125 12.1666 31.125ZM12.1666 31.125C11.3931 31.125 10.6512 30.8177 10.1042 30.2707C9.55725 29.7237 9.24996 28.9819 9.24996 28.2083V7.79167C9.24996 7.01812 8.94267 6.27625 8.39569 5.72927C7.84871 5.18229 7.10684 4.875 6.33329 4.875C5.55974 4.875 4.81788 5.18229 4.2709 5.72927C3.72392 6.27625 3.41663 7.01812 3.41663 7.79167V10.7083C3.41663 11.0951 3.57027 11.466 3.84376 11.7395C4.11725 12.013 4.48819 12.1667 4.87496 12.1667H9.24996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
