'use client';

import React from 'react';
import { Breadcrumbs, BreadcrumbItem } from '@nextui-org/react';
import NextLink from 'next/link';

interface breadCrumbItem {
  name: string;
  path: string | any;
}

interface breadcrumbItemsArray {
  breadcrumbPath?: breadCrumbItem[];
}

const BreadcrumbComponent: React.FC<breadcrumbItemsArray> = ({
  breadcrumbPath,
}) => {
  return (
    <div className="hidden lg:block text-start">
      <Breadcrumbs separator="/" itemClasses={{ separator: 'px-2' }}>
        {breadcrumbPath?.map((item, index) => {
          return (
            <BreadcrumbItem key={index}>
              <NextLink
                href={item.path || ''}
                className={`text-body-lg-bold ${index == 0 && breadcrumbPath?.length != 1 ? 'text-light-base-primary' : ''}`}
              >
                {item.name}
              </NextLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbComponent;
