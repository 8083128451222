import React from 'react';
interface Props {
  color?: string;
  width?: string;
  height?: string;
}
export const NotificationIcon: React.FC<Props> = ({ color, width, height }) => {
  return (
    <svg
      width={width || '44'}
      height={height || '44'}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="43" height="43" rx="21.5" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="43"
        height="43"
        rx="21.5"
        stroke={color || '#E0E0E0'}
      />
      <path
        d="M16 18C16 16.4087 16.6321 14.8826 17.7574 13.7574C18.8826 12.6321 20.4087 12 22 12C23.5913 12 25.1174 12.6321 26.2426 13.7574C27.3679 14.8826 28 16.4087 28 18C28 25 31 27 31 27H13C13 27 16 25 16 18Z"
        stroke="#6D788D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3 31C20.4674 31.3044 20.7135 31.5583 21.0125 31.7352C21.3116 31.912 21.6526 32.0053 22 32.0053C22.3475 32.0053 22.6885 31.912 22.9876 31.7352C23.2866 31.5583 23.5327 31.3044 23.7 31"
        stroke="#6D788D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
